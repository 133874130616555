import { graphql, StaticQuery } from 'gatsby'
import React from 'react'


export default function DryVanEquipments() {
  return (
    <StaticQuery
      query={graphql`
        query singleEquip {
          allWpPage(filter: { databaseId: { eq: 23 } }) {
            nodes {
              title
              Equipment {
                equipmentBox {
                  equipmentBoxTitle
                  equipmentServicesList
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="equipmentBoxes innerEquipmentBoxes">
          {data.allWpPage.nodes[0].Equipment.equipmentBox.map(item => (
            <div className="singleEquipmentBox">
              <div
                className="equipmentBoxTitle"
                dangerouslySetInnerHTML={{ __html: item.equipmentBoxTitle }}
              />
              <div
                className="equipmentServicesList"
                dangerouslySetInnerHTML={{
                    __html: item.equipmentServicesList,
                  }}
              />
            </div>
            ))} 
        </div>
      )}
    />
  )
}
