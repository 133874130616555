import { graphql, Link, StaticQuery } from "gatsby"
import React from 'react'


export default function FlatbedsEquipments(){
  return (
    <StaticQuery
      query={graphql`
        query singleEquipFB{
          allWpPage(filter: {databaseId: {eq: 634}}) {
            nodes {
              title
              Equipment {
                equipmentBox {
                  equipmentBoxTitle
                  equipmentServicesList
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="equipmentBoxes innerEquipmentBoxes">
          <div className="equipIntro">
            <h1 className="tabHeading">Flatbeds</h1>
            <p>
              To know more about roadLINX’s&nbsp;
              <Link to="/services/flatbed-freight/">Flatbed Trucking Trailer Rental Service</Link>
              .
            </p>
          </div>
          {data.allWpPage.nodes[0].Equipment.equipmentBox.map(item=> (
            <div className="singleEquipmentBox">
              <div className="equipmentBoxTitle" dangerouslySetInnerHTML={{ __html: item.equipmentBoxTitle }} />
              <div className="equipmentServicesList" dangerouslySetInnerHTML={{ __html: item.equipmentServicesList }} />
            </div>
            )
            )}
        </div>
      )}
    />
  )
} 
