import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from 'react'
import CallFurthur from '../components/CallFurthur'
import ChassisTypesEquipments from "../components/Equipment/ChassisTypesEquipments"
import DryVanEquipments from "../components/Equipment/DryVanEquipments"
import FlatbedsEquipments from "../components/Equipment/FlatbedsEquipments"
import IntermodalContainersEquipments from "../components/Equipment/IntermodalContainersEquipments"
import TemperatureControlledEquipments from "../components/Equipment/TemperatureControlledEquipments"
import Hero from '../components/EquipmentTabs/Hero'
import Footer from '../components/Footer'
import Layout from '../components/Layout'
import Location from '../components/Location'
import SEO from '../components/seo'

const EquipmentDetailTemplate = ({ data }) => {
  const { seo, title } = data?.wpPage
  const image = data?.desktop?.childImageSharp?.fluid
  const equipmentDetails = data?.wpPage
  const equipBanner = equipmentDetails.EquipmentDetails.mainBanner;
  return (
    <Layout>
      <SEO title={seo.title} description={seo.metaDesc} />
      <div className="equipmentDetailsPage">
      {
        equipmentDetails !== null &&
        <Hero type="primary" image={image} title={title}/> 
      }
      
      <div className="equipmentTypes">
        <div className="equipmentsList">
            <div className="equipBox">
              <Link to="/equipment/dry-van/">
                <StaticImage src="../images/menu-icons/equipments/dry-vans.png" alt="A kitten" />
                <span>Dry Van</span>
              </Link>
            </div>
            <div className="equipBox">
              <Link to="/equipment/temperature-controlled/">
                <StaticImage src="../images/menu-icons/equipments/temperature.png" alt="A kitten" />
                <span>Temperature Controlled</span>
              </Link>
            </div>
            <div className="equipBox">
              <Link to="/equipment/intermodal-containers/">
                <StaticImage src="../images/menu-icons/equipments/intermodal.png" alt="A kitten" />
                <span>Intermodal Containers</span>
              </Link>
            </div>
            <div className="equipBox">
              <Link to="/equipment/storage-trailers/">
                <StaticImage src="../images/menu-icons/equipments/storage.png" alt="A kitten" />
                <span>Storage Trailers</span>
              </Link>
            </div>
            <div className="equipBox">
              <Link to="/equipment/flatbeds/">
                <StaticImage src="../images/menu-icons/equipments/flatbeds.png" alt="A kitten" />
                <span>Flatbeds</span>
              </Link>
            </div>
            <div className="equipBox">
              <Link to="/equipment/chassis-types/">
                <StaticImage src="../images/menu-icons/equipments/chassis-types.png" alt="A kitten" />
                <span>Chassis Types</span>
              </Link>
            </div>
        </div>
      </div>
      <section className="singleEquipment">
        <div className="singleEquipmentContent">
        {
          equipmentDetails.parentDatabaseId === 23 ?
          <DryVanEquipments/> :
          equipmentDetails.parentDatabaseId === 24 ?
          <TemperatureControlledEquipments /> : 
          equipmentDetails.parentDatabaseId === 634 ?
          <FlatbedsEquipments/> : 
          equipmentDetails.parentDatabaseId === 331 ?
          <IntermodalContainersEquipments/> : 
          equipmentDetails.parentDatabaseId === 29 ?
          <ChassisTypesEquipments/> : null
        }
        </div>
        <div className="singleEquipmentSpecifications specsBox">
            <div className="equipmentSummary">
              <GatsbyImage image={getImage(equipmentDetails?.EquipmentDetails?.equipmentDetails?.equipmentBanner?.localFile?.childImageSharp?.gatsbyImageData)} alt="specs image" />
              <h3 className="specCount"><span>{equipmentDetails?.EquipmentDetails?.equipmentDetails?.equipmentSpecifications?.size}</span></h3>
              <div className="equipOverview" dangerouslySetInnerHTML={{ __html: equipmentDetails?.EquipmentDetails?.equipmentDetails?.equipmentSummary }}></div>
            </div>
            {equipmentDetails?.EquipmentDetails?.equipmentDetails?.equipmentSpecifications?.specificationsTable?.map(item=>
              <div className="equipmentSummaryTable" dangerouslySetInnerHTML={{ __html: item?.specsTable }}></div>  
            )}
        </div>
      </section>
      
      

      <CallFurthur />
      <Location />
      <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query getEquipmentDetailAndDesktop($id: String) {
    wpPage(id: { eq: $id }) {
      parentDatabaseId
      seo {
        title
        metaDesc
      }
      title
      EquipmentDetails {
        overview
        mainBanner {
          localFile {
              childImageSharp {
                gatsbyImageData
              }
          }
        }
        equipmentDetails {
          equipmentBanner {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          equipmentSummary
          equipmentSpecifications {
            size
            specificationsTable {
              specsTable
            }
          }
        }
      }
    }
    desktop: file(relativePath: { eq: "equipmentBanner.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default EquipmentDetailTemplate
