import { graphql, Link, StaticQuery } from "gatsby"
import React from 'react'


export default function TemperatureControlledEquipments(){
  return (
    <StaticQuery
      query={graphql`
        query singleEquipTC{
          allWpPage(filter: {databaseId: {eq: 24}}) {
            nodes {
              title
              Equipment {
                equipmentBox {
                  equipmentBoxTitle
                  equipmentServicesList
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="equipmentBoxes innerEquipmentBoxes">
          <div className="equipIntro">
            <h1 className="tabHeading">Temperature Controlled</h1>
            <p>
              To know about our&nbsp;
              <Link to="/services/reefer-freight/">Refrigerated freight&nbsp;shipping service</Link>
                &nbsp;&amp;&nbsp;
              <Link to="/services/heated-trucks/">Heated truck shipping service</Link>
              .
            </p>
          </div>
          {data.allWpPage.nodes[0].Equipment.equipmentBox.map(item=> (
            <div className="singleEquipmentBox">
              <div className="equipmentBoxTitle" dangerouslySetInnerHTML={{ __html: item.equipmentBoxTitle }} />
              <div className="equipmentServicesList" dangerouslySetInnerHTML={{ __html: item.equipmentServicesList }} />
            </div>
            )
            )}
        </div>
      )}
    />
  )
}
