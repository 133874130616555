import { graphql, StaticQuery } from "gatsby"
import React from 'react'


export default function IntermodalContainersEquipments(){
  return (
    <StaticQuery
      query={graphql`
        query singleEquipIMC{
          allWpPage(filter: {databaseId: {eq: 331}}) {
            nodes {
              title
              Equipment {
                equipmentBox {
                  equipmentBoxTitle
                  equipmentServicesList
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="equipmentBoxes innerEquipmentBoxes">
          <div className="equipIntro">
            <h1 className="tabHeading">Intermodal Containers</h1>
          </div>
          {data.allWpPage.nodes[0].Equipment.equipmentBox.map(item=> (
            <div className="singleEquipmentBox">
              <div className="equipmentBoxTitle" dangerouslySetInnerHTML={{ __html: item.equipmentBoxTitle }} />
              <div className="equipmentServicesList" dangerouslySetInnerHTML={{ __html: item.equipmentServicesList }} />
            </div>
            )
            )}
        </div>
      )}
    />
  )
}
